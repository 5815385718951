import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import "./users.scss";
import "../../../../../../assets/scss/tooltip.scss";
const RecruiterAdminFilter = ({ list, id, img, selectUserFilter }) => {
    const [userList, setUserList] = useState([]);
console.log(list);
//return false
    useEffect(() => {
        const result = [];
        const map = new Map();
        for (const item of list) {
            if (!map.has(item.original.candidateInfo?.recAdminInfo.uid)) {
                map.set(item.original.candidateInfo?.recAdminInfo.uid, true);
                result.push({
                    id: item.original.candidateInfo?.recAdminInfo.uid,
                    name: item.original.candidateInfo?.recAdminInfo.firstName,
                    userImg: item.original.candidateInfo?.recAdminInfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.candidateInfo?.recAdminInfo.uid === item.original.candidateInfo?.recAdminInfo.uid;
                    }).length
                });
            }
        }
        result.sort(function (a, b) {
            return b.count - a.count;
        });
        setUserList(result)
    }, [list])

    return (
        <React.Fragment>
            <Dropdown>
                <Dropdown.Toggle variant="" className="tableFilterRoundButton">
                    {
                        (id === '0') ? 'RR' : <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + img} />
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu className="dk-userNumfliterMenu">
                    <div className="d-flex">
                        {
                            userList && userList.map((item, index) =>
                                <Dropdown.Item href="#">
                                    <div className="tooltipMain" data-type="RAID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} className="filterImg" alt={item.name} data-id={item.id} data-type="RAID" data-img={item.userImg} onClick={selectUserFilter} />
                                        <div className="tooltipText">{item.name}</div>
                                    </div>
                                    <span className="recNoCount" data-type="RAID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                        {item.count}
                                    </span>
                                </Dropdown.Item>
                            )
                        }
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default RecruiterAdminFilter;