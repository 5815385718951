import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const todayslistTable = createAsyncThunk(
  "todaysAds/adstracker",
  async (thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/ads/todays-ads",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token.access_token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addTodaysAds = createAsyncThunk(
  "todaysAds/add_tracker",
  async (
    {
      recruiter,
      jobRef,
      jobTitle,
      company,
      jobBoard,
      roleType,
      language,
      project,
      city,
      totalApplication,
      country,
      postedDate,
      expiredDate,
    },
    thunkAPI
  ) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/ads/todays-ads",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token.access_token,
          },
          body: JSON.stringify({
            recruiter,
            jobRef,
            jobTitle,
            company,
            jobBoard,
            roleType,
            language,
            project,
            city,
            totalApplication,
            country,
            postedDate,
            expiredDate,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const todaysDataMove = createAsyncThunk(
  "todaysAds/todaysTrackerdatamove",
  async ({ ids }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/ads/todays-ads/move2live",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token.access_token,
          },
          body: JSON.stringify({ ids }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fethById = createAsyncThunk(
  "todaysAds/ads_tracker_details",
  async ({ id }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/ads/todays-ads/" + id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token.access_token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteTracker = createAsyncThunk(
  "todaysAds/ads_delete",
  async ({ id }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/ads/todays-ads/" + id,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token.access_token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateTracker = createAsyncThunk(
  "todaysAds/update_tracker",
  async (
    {
      id,
      recruiter,
      jobRef,
      jobTitle,
      company,
      jobBoard,
      roleType,
      language,
      project,
      city,
      totalApplication,
      country,
      postedDate,
      expiredDate,
    },
    thunkAPI
  ) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/ads/todays-ads/" + id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token.access_token,
          },
          body: JSON.stringify({
            recruiter,
            jobRef,
            jobTitle,
            company,
            jobBoard,
            roleType,
            language,
            project,
            city,
            totalApplication,
            country,
            postedDate,
            expiredDate,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateStatus = createAsyncThunk(
  "todaysAds/update_status_tracker",
  async ({ id, status }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/ads/todays-ads/updateStatus/" + id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token.access_token,
          },
          body: JSON.stringify({
            status,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const reorderData = createAsyncThunk(
  "todaysAds/reorderlist",
  async ({ ids }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/ads/todays-ads/reorder",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token.access_token,
          },
          body: JSON.stringify({
            ids,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const todaysAdsTrackerSlice = createSlice({
  name: "todaysAds_tracker",
  initialState: {
    rolesList: [],
    languageList: [],
    dataList: [],
    detailData: [],
    isLoading: false,
    isDataLoading: false,
    isInsert: false,
    isError: false,
    isUpdate: false,
    isDelete: false,
    isSuccess: false,
    isReoder: false,
    isDataMove: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isDataLoading = false;
      state.isError = false;
      state.isInsert = false;
      state.isUpdate = false;
      state.isDelete = false;
      state.isSuccess = false;
      state.isReoder = false;
      state.isDataMove = false;
      state.errorMessage = "";
      return state;
    },
  },
  extraReducers: {
    [reorderData.pending]: (state) => {
      state.isloading = true;
      state.isReoder = false;
    },
    [reorderData.fulfilled]: (state, { payload }) => {
      state.isReoder = true;
      state.isloading = false;
      state.msg = "Reorder successfully.";
    },
    [reorderData.rejected]: (state, { payload }) => {
      state.isloading = false;
      state.isError = payload.message;
      state.msg = payload.message;
    },

    [todaysDataMove.pending]: (state) => {
      state.isDataLoading = false;
    },
    [todaysDataMove.fulfilled]: (state, action) => {
      state.isDataLoading = false;
      // console.log(payload)
      state.isDataMove = true;
      state.msg = "Data move from  todays to live";
      state.dataList = state.dataList.filter(
        (item) => item.id !== action.meta.arg.ids
      );
    },
    [todaysDataMove.rejected]: (state, { payload }) => {
      state.isError = payload.message;
      state.errorMessage = payload.message;
      state.isDataLoading = false;
    },
    [todayslistTable.pending]: (state) => {
      state.isDataLoading = true;
    },
    [todayslistTable.fulfilled]: (state, { payload }) => {
      state.dataList = payload.result;
      state.isDataLoading = false;
    },
    [todayslistTable.rejected]: (state, { payload }) => {
      state.isError = payload.message;
      state.errorMessage = payload.message;
      state.isDataLoading = false;
    },

    [addTodaysAds.pending]: (state) => {
      state.isLoading = true;
    },
    [addTodaysAds.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isInsert = true;
      state.dataList.push(payload.result);
      state.msg = payload.message;
    },
    [addTodaysAds.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isInsert = false;
      state.isError = true;
      state.msg = payload.message;
    },
    [fethById.pending]: (state) => {},
    [fethById.fulfilled]: (state, { payload }) => {
      state.detailData = payload.result;
    },
    [fethById.rejected]: (state, { payload }) => {},
    [deleteTracker.pending]: (state) => {},
    [deleteTracker.fulfilled]: (state, action) => {
      state.dataList = state.dataList.filter(
        (item) => item.id !== action.meta.arg.id
      );
      state.isDelete = true;
    },
    [deleteTracker.rejected]: (state, { payload }) => {
      state.isError = payload.message;
      state.errorMessage = payload.message;
    },
    [updateTracker.pending]: (state) => {
      state.isLoading = true;
    },
    [updateTracker.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isUpdate = true;
      let mainArray = state.dataList;
      let finalResult = mainArray.map((r) => {
        if (r.id === payload.result.id) {
          return payload.result;
        } else {
          return r;
        }
      });
      state.dataList = finalResult;
      state.msg = payload.message.msgBody;
    },
    [updateTracker.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.msg = payload.message.msgBody;
    },
    [updateStatus.pending]: (state) => {
      state.isLoading = true;
      state.isUpdate = false;
    },
    [updateStatus.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isUpdate = true;
      let mainArray = state.dataList;
      let finalResult = mainArray.map((r) => {
        if (r.id === payload.result.id) {
          return payload.result;
        } else {
          return r;
        }
      });
      state.dataList = finalResult;
      state.msg = payload.message.msgBody;
    },
    [updateStatus.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;

      state.msg = payload.message.msgBody;
    },
  },
});

export const { clearState } = todaysAdsTrackerSlice.actions;
export default todaysAdsTrackerSlice.reducer;
