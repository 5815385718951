import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import "./createnew.css";
//import DummySelectPiker from '../../../../ui/SelectPicker/DummySelectPiker';
import Submit from "../../../../ui/submitButton";
import UserList from "../../../../ui/userList/";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../utils/messages/messageSlice";
import {
  addTodaysAds,
  clearState,
} from "../../../../../slice/ads/todaysAds/todaysAdsSlice";
//import SelectInstance from '../../../../ui/SelectPicker/SelectInstance'
import JobBoardDropdown from "../../dropdown/JobBoardDropdown";
import CompanyDropdown from "../../dropdown/CompanyDropdown";
import RoleTypeDropdown from "../../dropdown/RoleTypeDropdown";
import LanguageDropdown from "../../dropdown/LanguageDropdown";

const CreateNew = ({ onHide }) => {
  const dispatch = useDispatch();
  const { adsJobboardList, adsCompanyList, adsLanguageList, adsRoleTypeList } =
    useSelector((state) => state.common);
  const {
    dataList,
    isDataLoading,
    isInsert,
    isUpdate,
    isError,
    isDelete,
    errorMessage,
    jobBoardList,
    companyList,
    rolesList,
    languageList,
  } = useSelector((state) => state.todaysAdsTracker);

  const [jobRef, setJobRef] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [jobBoard, setJobBoard] = useState("");
  const [roleType, setRoleType] = useState("");
  const [language, setLanguage] = useState("");
  const [project, setProject] = useState("");
  const [totalApplications, setTotalApplications] = useState("0");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postedDate, setPostedDate] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [userId, setUserId] = useState("0");
  const [userImg, setUserImg] = useState("");
  const [userImgName, setUserImgName] = useState("");
  const [totalJobs, setTotalJobs] = useState("0");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const selectUser = (e) => {
    setUserImgName(e.target.alt);
    setUserImg(e.target.src);
    setUserId(e.target.dataset.id);
  };

  const handleChangeSelect = (event, data) => {
    const { name, value } = data;

    switch (name) {
      case "company":
        setCompanyId(value);
        break;
      case "jobBoard":
        setJobBoard(value);
        break;
      case "role":
        setRoleType(value);
        break;
      case "language":
        setLanguage(value);
        break;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "jobRef":
        setJobRef(value);
        break;
      case "jobTitle":
        setJobTitle(value);
        break;
      case "totalApplications":
        setTotalApplications(value);
        break;
      case "project":
        setProject(value);
        break;
      case "city":
        setCity(value);
        break;
      case "country":
        setCountry(value);
        break;
      case "postedDate":
        setPostedDate(value);
        break;
      case "expireDate":
        setExpireDate(value);
        break;
      default:
        break;
    }
  };
  const handleButtonClick = async (e) => {
    e.preventDefault();

    if (userId == "0") {
      dispatch(showError({ msg: "Please fill all fields." }));
    } else {
      if (!loading) {
        setSuccess(false);
        setLoading(true);
        await dispatch(
          addTodaysAds({
            recruiter: userId,
            jobRef: jobRef,
            jobTitle: jobTitle,
            company: companyId,
            jobBoard: jobBoard,
            roleType: roleType,
            language: language,
            project: project,
            city: city,
            totalApplication: totalApplications,
            country: country,
            postedDate: postedDate,
            expiredDate: expireDate,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (isInsert) {
      dispatch(showSuccess({ msg: "Tracker added successfully." }));
      dispatch(clearState());
      setJobRef("");
      setJobTitle("");
      setCompanyId("");
      setJobBoard("");
      setTotalJobs("0");
      setTotalApplications("0");
      setRoleType("");
      setLanguage("");
      setCity("");
      setCountry("");
      setPostedDate("");
      setExpireDate("");
      setUserId("0");
      onHide();
      setSuccess(true);
      setLoading(false);
    }
    if (isError) {
      dispatch(showError({ msg: errorMessage }));
      dispatch(clearState());
      setSuccess(false);
      setLoading(false);
    }
  }, [isInsert, isError]);

  return (
    <React.Fragment>
      <Modal
        show={true}
        onHide={onHide}
        dialogClassName="dk-compaginsCreateNewPop"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Post New Ad</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-compaginsCreateNew">
          <form>
            <div className="row">
              <div className="col-md-3 mr-auto">
                <div className="form-group">
                  <label htmlFor="">Recruiter</label>
                  <label htmlFor="" className="mb-0">
                    <input type="hidden" id="userId" value="0" />
                    <Dropdown className="dk-globalUserDropdown todays12">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="todyAddToggle"
                      >
                        {userId !== "0" ? (
                          <img src={userImg} className="select-user-img" />
                        ) : (
                          <i className="fas fa-user"></i>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dk-adstracker-DrpMenu12">
                        <div className="d-flex">
                          <UserList onClick={selectUser} />
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>No of Ads</label>
                  <input
                    type="text"
                    className="form-control broderContrl"
                    name="totalApplications"
                    value={totalApplications}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-9">
                <div className="form-group">
                  <label>Job Title</label>
                  <input
                    type="text"
                    className="form-control broderContrl"
                    name="jobTitle"
                    value={jobTitle}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Job Board</label>
                  <div className="dk-camaadNewFilter">
                    <JobBoardDropdown
                      list={adsJobboardList}
                      type="jobBoard"
                      placeholder="Select"
                      onChange={handleChangeSelect}
                      name="jobBoard"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Job Ref</label>
                  <input
                    type="text"
                    className="form-control broderContrl"
                    name="jobRef"
                    value={jobRef}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Company</label>
                  <div className="dk-camaadNewFilter">
                    <CompanyDropdown
                      list={adsCompanyList}
                      type="company"
                      placeholder="Select"
                      onChange={handleChangeSelect}
                      name="company"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Project</label>
                  <div className="dk-camaadNewFilter">
                    <input
                      type="text"
                      className="form-control broderContrl"
                      name="project"
                      value={project}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Language</label>
                  <div className="dk-camaadNewFilter">
                    <LanguageDropdown
                      list={adsLanguageList}
                      type="language"
                      placeholder="Select"
                      onChange={handleChangeSelect}
                      name="language"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Role Type</label>
                  <div className="dk-camaadNewFilter">
                    <RoleTypeDropdown
                      list={adsRoleTypeList}
                      type="role"
                      placeholder="Select"
                      onChange={handleChangeSelect}
                      name="role"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Country</label>
                  <input
                    type="text"
                    className="form-control broderContrl"
                    name="country"
                    value={country}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>City</label>
                  <div className="dk-camaadNewFilter">
                    <input
                      type="text"
                      className="form-control broderContrl"
                      name="city"
                      value={city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Posted Date</label>
                  <input
                    type="date"
                    className="form-control broderContrl"
                    name="postedDate"
                    value={postedDate}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Expire Date</label>
                  <input
                    type="date"
                    className="form-control broderContrl"
                    name="expireDate"
                    value={expireDate}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="dk-adstrackbtnCont">
                  <Submit
                    txt="ADD"
                    loading={loading}
                    success={success}
                    onClick={handleButtonClick}
                    className="dk-adstrackaddbtn"
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CreateNew;
