import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { InputGroup, FormControl } from "react-bootstrap";
// import Dropdown from "react-bootstrap/Dropdown";
// import SimpleDropDwn from "./SimpleDropDwn";
// import UserSearchFilter from "./SearchInputDropDwn";
// import AirDatepicker from "air-datepicker";
// import en from "air-datepicker/locale/en";
// import UserList from "../../../../../ui/userList/userListByRole";
//import JoditEditor from "../../../../../ui/JoditEditor";
import "air-datepicker/air-datepicker.css";
import { clearState } from "../../../../../../slice/jobs/jobsSlice";

const JobSummary = ({ jobId }) => {
  const dispatch = useDispatch();

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setStatus(jobDetails.status);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);
  let ids = data.id;

  let gross_value = "";
  if (data.gross_title === null) {
    gross_value = "Monthly Gross";
  } else {
    if (data.gross_title === "Monthly Gross") {
      gross_value = "Annual Gross";
    } else {
      gross_value = "Monthly Gross";
    }
  }

  let bgcolur = "";
  let txtColor = "";

  if (data.status == "Active") {
    bgcolur = "#02a786";
    txtColor = "#fff";
  } else if (data.status == "Inactive") {
    bgcolur = "#b55355";
    txtColor = "#fff";
  } else if (data.status == "Qualify") {
    bgcolur = "#d58b5d";
    txtColor = "#fff";
  } else if (data.status == "On Hold") {
    bgcolur = "#8b572a";
    txtColor = "#fff";
  } else if (data.status == "Closed") {
    bgcolur = "#da3d5e";
    txtColor = "#fff";
  } else {
    bgcolur = "#da3d5e";
    txtColor = "#fff";
  }

  console.log(data);
  return (
    <>
      <div className="row m-0">
        <div className="col-md-4 p-0 pr-3 dk-suMMRightCon dk-jobsuMMBottomSec">
          <div className="col-md-12 col-sm-12 dk-right-boxjobDetails p-0">
            <div className="row m-0">
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">JOB STATUS</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div
                  className="p-3 dkg-answer-block"
                  style={{
                    backgroundColor: bgcolur,
                    borderColor: bgcolur,
                    color: txtColor,
                  }}
                >
                  {data.status}
                </div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">Total Jobs</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.no_of_jobs}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">MONTHLY SALARY</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">
                  {data.monthly_salary}
                </div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">PERFORMANCE BONUS</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">
                  {data.performance_bonus}
                </div>
              </div>
              {/* <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">MONTHLY BONUS</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.annual_gross}</div>
              </div> */}
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">INDUSTRY</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.industry}</div>
              </div>
              {/* <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">
                  {data.gross_title !== null
                    ? data.gross_title
                    : "Gross Salary"}
                </div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.annual_gross}</div>
              </div> */}

              {/* <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">NET SALARY PM</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.average_net_pm}</div>
              </div> */}

              {/* <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">AVG NET SALARY</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.average_net_pm}</div>
              </div> */}
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">COMPANY </div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.company}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">CATEGORY </div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.category}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">COUNTRY </div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.job_country}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">CITY/TOWN</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.location}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">PROJECT </div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.project}</div>
              </div>

              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">ROLE TYPE</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.role}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">LANGUAGE </div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.lang}</div>
              </div>
              {/* <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">LOCALITY</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.locality}</div>
              </div> */}
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">CONTRACT</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.contract}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">WORK PERMIT</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.work_permit}</div>
              </div>
              {/* <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">RELOCATION </div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.availability}</div>
              </div> */}
              {/* <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">WORKPLACE</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.shifts_work}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">WORK DAYS</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.workdays}</div>
              </div> */}
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">WORK FROM</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.workplace}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">WORK TYPE</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.worktype}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">WORK DAYS</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.workdays}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">WORK HOURS</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.shifts_work}</div>
              </div>
              <div className="col-md-5 left-title-blocks p-0">
                <div className="fist-col-div p-3">START DATE 1</div>
              </div>
              <div className="col-md-7 left-title-blocks p-0">
                <div className="p-3 dkg-answer-block">{data.start_date1}</div>
              </div>

              <div
                className="col-md-5 left-title-blocks p-0"
                style={{ borderBottom: "none" }}
              >
                <div className="fist-col-div p-3">START DATE 2</div>
              </div>
              <div
                className="col-md-7 left-title-blocks p-0"
                style={{ borderBottom: "none" }}
              >
                <div className="p-3 dkg-answer-block">{data.start_date2}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8 p-0 pl-3 dk-suMMRightCon dk-jobsuMMBottomSec">
          <div className="col-md-12 col-sm-12 dk-jobsuMMBottomBoxBg p-0">
            <div className="dk-boxjobDetails">
              <h1 className="dkg-jobdetail-maintitle">
                {data.title_description != "" && data.title_description != null
                  ? data.title_description
                  : ""}
              </h1>

              {/* {
                data.title_description != "" && data.title_description != null ? <div
                  dangerouslySetInnerHTML={{
                    __html: data.title_description,
                  }}
                ></div>
                  :
                  ''} */}
              {data.company_description != "" &&
              data.company_description != null ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.company_description,
                  }}
                ></div>
              ) : (
                ""
              )}
              {data.responsibility_description != "" &&
              data.responsibility_description != null ? (
                <>
                  <h1>Responsibilities</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.responsibility_description,
                    }}
                  ></div>
                </>
              ) : (
                ""
              )}
              {data.requirnment_description != "" &&
              data.requirnment_description != null ? (
                <>
                  <h1>Requirements</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.requirnment_description,
                    }}
                  ></div>
                </>
              ) : (
                ""
              )}

              {data.salary_benifit_description != "" &&
              data.salary_benifit_description != null ? (
                <>
                  <h1>Salary & Benefits</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.salary_benifit_description,
                    }}
                  ></div>
                </>
              ) : (
                ""
              )}
              {data.relocation_support != "" &&
              data.relocation_support != null ? (
                <>
                  <h1>Relocation Support</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.relocation_support,
                    }}
                  ></div>
                </>
              ) : (
                ""
              )}
              {data.gdpr_description != "" && data.gdpr_description != null ? (
                <>
                  <h1>GDPR Consent</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.gdpr_description,
                    }}
                  ></div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobSummary;
