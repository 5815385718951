import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import "./todayads.css";
import "./todayadsnew.scss";
import { DropdownButton } from "react-bootstrap";
import { FaPlus, FaSearch, FaUser } from "react-icons/fa";
//import DummySelectPiker from '../../Ui/SelectPicker/DummySelectPiker';
import CreateNewModal from "./modal/CreateNew";
//import ReorderModal from './modal/Reorder'
import EditModal from "./modal/Edit";
import DeleteModal from "../../../ui/delete";
import BulkMoveData from "../../../ui/delete/BulkMoveAdData";
import ViewModal from "./modal/View";

import TableLoader from "../../../ui/tableLoader";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { useDispatch, useSelector } from "react-redux";
import {
  todayslistTable,
  fethById,
  deleteTracker,
  updateStatus,
  todaysDataMove,
  clearState,
} from "../../../../slice/ads/todaysAds/todaysAdsSlice";

import Tooltip from "../../../ui/tooltip";
import { GlobalSearch } from "../GlobalSearch";
import UserFilterToggle from "../../../ui/tableUserFilter";
import FilterSelectInstance from "../filter/SelectInstance";
import DateFormat from "../../../ui/dateFormats/DateFormat";
import CustomDateFormat from "../../../ui/dateFormats";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../utils/messages/messageSlice";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "../../../ui/pagination/";
import TodayAdsReorder from "./modal/Reorder";
import $ from "jquery";
import "./extraDiv.scss";
import CircularProgress from "@material-ui/core/CircularProgress";

function Table({
  loading,
  columns,
  data,
  title,
  isInsert,
  isUpdate,
  isDataDelete,
}) {
  const [userFilterId, setUserFilterId] = useState("0");
  const [userFilterImg, setUserFilterImg] = useState("0");
  const [isAddNew, setIsAddNew] = useState(false);
  const [isReorderModal, setIReorderModal] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);

  const { activeData, inactiveData } = useSelector(
    (state) => state.todaysAdsTracker
  );

  const handlePageChange = (event, value) => {
    gotoPage(Number(value) - 1);
  };

  const showaddNewModal = () => {
    setIsAddNew(true);
  };
  const hideaddNewModal = () => {
    setIsAddNew(false);
  };
  const showreorderModal = () => {
    setIReorderModal(true);
  };
  const hidereorderModal = () => {
    setIReorderModal(false);
    //setIsReorder(false)
  };
  const clickFilterUser = (e) => {
    setUserFilterId(e.target.id);
    setUserFilterImg(e.target.src);
    setFilter("userInfo", e.target.id);
  };

  const deleteFilter = (name) => {
    setFilter(name, "");
    if (name === "userInfo") {
      setUserFilterId("0");
      setUserFilterImg("0");
    }
  };
  const handleChangeSelect = (event, data) => {
    const { name, value } = data;
    switch (name) {
      case "statusId":
        setFilter(name, value);
        break;
    }
  };
  const showcreateNewModal = () => {
    setIsCreateNew(true);
  };
  const hidecreateNewModal = () => {
    setIsCreateNew(false);
  };

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageIndex: 0,
        pageSize: 100,
        hiddenColumns: ["userInfo", "statusId", "userId"],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    setFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  const { globalFilter } = state;

  useEffect(() => {
    setFilter("userInfo", "");
    setUserFilterId("0");
    setUserFilterImg("0");
    setGlobalFilter("0");
  }, []);

  useEffect(() => {
    if (isInsert || isUpdate || isDataDelete) {
      setFilter("userInfo", "");
      setUserFilterId("0");
      setUserFilterImg("0");
      setGlobalFilter("0");
    }
  }, [isInsert, isUpdate, isDataDelete]);

  const [caseStatusFilterDDLText, setCaseStatusFilterDDLText] =
    useState("Status");
  const onSelectStatusFilter = (eventKey) => {
    switch (eventKey) {
      case "1":
        setCaseStatusFilterDDLText("Posted");
        setFilter("statusId", "1");
        break;

      case "0":
        setCaseStatusFilterDDLText("Not Posted");
        setFilter("statusId", "0");
        break;
      default:
        break;
    }
  };

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    switch (type) {
      case "status":
        setCaseStatusFilterDDLText("Status");
        setFilter("statusId", "");
        break;

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {isCreateNew ? <CreateNewModal onHide={hidecreateNewModal} /> : null}
      {isAddNew ? <CreateNewModal onHide={hideaddNewModal} /> : null}
      {isReorderModal ? (
        <TodayAdsReorder onHide={hidereorderModal} dataList={data} />
      ) : null}
      <div className="dk-todayadsPageHead">
        <div className="d-flex align-items-center justify-content-between">
          <div className="dk-todayadsLeft">
            <div className="dk-mailshotsSearch mr-2">
              <div className="input-group">
                <span className="d-flex">
                  <GlobalSearch
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                </span>
              </div>
            </div>

            <button onClick={showcreateNewModal} className="filterTable mr-2">
              <FaPlus />
            </button>
            <button className="filterTable mr-2">
              <FaSearch />
            </button>

            <div className="dk-filterUserTabs dropdown">
              <UserFilterToggle
                userFilterId={userFilterId}
                userFilterImg={userFilterImg}
                list={tableInstance.flatRows}
                selectUser={clickFilterUser}
                deleteFilter={deleteFilter}
              />
            </div>
          </div>
          <h2 className="title">TODAY ADS TO POST</h2>
          <div className="dk-todayadsTopRight d-flex">
            <button onClick={showreorderModal} className="mr-2">
              <i className="fas fa-arrows-alt"></i>
            </button>
            {/* <div className="dk-reSLoginFilter">
                            <FilterSelectInstance list={tableInstance.flatRows} type="status" placeholder="Status" name="statusId" onChange={handleChangeSelect} />
                        </div> */}
            <div className="dk-reSLoginFilter">
              <DropdownButton
                className="dk-cli-status-dropdown"
                title={caseStatusFilterDDLText}
                onSelect={onSelectStatusFilter}
              >
                <Dropdown.Item
                  eventKey="1"
                  href="#"
                  className="dk-activeBtn"
                  style={{ backgroundColor: "#368836", color: "#fff" }}
                >
                  Posted
                  <span className="dkg-lv-statuscounter">
                    {
                      tableInstance.flatRows.filter(function (val) {
                        return val.original.status === 1;
                      }).length
                    }
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="0"
                  href="#"
                  className="dk-inActiveBtn"
                  style={{ backgroundColor: "#dc3545", color: "#fff" }}
                >
                  Not Posted
                  <span className="dkg-lv-statuscounter">
                    {
                      tableInstance.flatRows.filter(function (val) {
                        return val.original.status === 0;
                      }).length
                    }
                  </span>
                </Dropdown.Item>
              </DropdownButton>
              {caseStatusFilterDDLText !== "Status" ? (
                <i
                  className="fas fa-times-circle crossUserFilter dk-lrftCross"
                  data-type="status"
                  onClick={removeTag}
                ></i>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className=" dk-todayAdsTableCon-14 table-responsive">
        <table className="table dk-todayAdsTable-14" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {loading ? (
            <TableLoader colSpan="17" />
          ) : (
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className="col-md-12 d-none">
        <div className="dk-resPaginationCon">
          <div className="dk-resPagination">
            <div className="dk-resLeftPag">
              <div className="dk-texCounter">
                Showing {pageIndex + 1} to {pageOptions.length} (of{" "}
                {tableInstance.rows.length} entries)
              </div>
            </div>
            <div className="dk-resrightPag">
              <TablePagination count={pageCount} onChange={handlePageChange} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tblDdlActive: {
    backgroundColor: "#31a886 !important",
    color: "#ffffff !important",
    minWidth: 90,
  },
  tblDdlInActive: {
    backgroundColor: "#d8345f !important",
    color: "#ffffff !important",
    minWidth: 90,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "green",
  },
}));

const TodayAds = ({ props }) => {
  const [isCreateNew, setIsCreateNew] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState([]);

  const {
    dataList,
    isDataLoading,
    isInsert,
    isUpdate,
    isError,
    isDelete,
    isReoder,
    msg,
    isDataMove,
  } = useSelector((state) => state.todaysAdsTracker);

  const [rowId, setRowId] = useState("0");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(todayslistTable({}));
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!isDataLoading && dataList && dataList.length > 0) {
      setData(dataList);
    }
  }, [isDataLoading, isDataMove, isInsert, isUpdate, isDelete]);

  useEffect(() => {
    if (isDelete) {
      dispatch(clearState());
      dispatch(showSuccess({ msg: "Delete successfully." }));
      // if (props.tableId != '0') {
      //     setData(dataList.filter(dataList => dataList.jobBoard.id === props.tableId))
      // } else {
      //setData(dataList)
      // }
      setRowId("0");
      hideDeleteModal();
    }
    if (isDataMove) {
      dispatch(clearState());
      dispatch(todayslistTable({}));
      setIsActive(false);
      setCheckBtnTxt("Check All");
      setCountCheck(0);
      setIsBulkMovePop(false);
      $(".chkCandidate").prop("checked", false);
      setIsActive(false);
      dispatch(showSuccess({ msg: msg }));
      setBulkLoad(false);
      hideDeleteModal();
    }
    if (isReoder) {
      dispatch(clearState());
      dispatch(todayslistTable({}));
    }
  }, [isDelete, isUpdate, isDataMove, isReoder]);

  const columns = useMemo(
    () => [
      {
        Header: "SNo.",
        title: "SNo.",
        Cell: ({ cell }) => (
          <React.Fragment>
            <div className="dk-smsTableCheckbox">
              <input
                type="checkbox"
                className="chkCandidate"
                name="chkCandidate[]"
                value={cell.row.original.id}
                onChange={(e) => toggleCheckbox(e, cell.row.original.id)}
              />
              {Number(cell.row.id) + 1}
              <div className="dk-adstractAction">
                <Link
                  to="#"
                  className="dk-view-btn ml-2"
                  onClick={() => showViewModal(cell.row.original.id)}
                >
                  <i className="fa fa-eye " aria-hidden="true"></i>
                </Link>
                <Link
                  to="#"
                  className="dk-edit-btn"
                  onClick={() => showeditModal(cell.row.original.id)}
                >
                  <i className="fa fa-edit " aria-hidden="true"></i>
                </Link>
                <Link
                  to="#"
                  className="dk-delete-btn"
                  onClick={() => showDeleteModal(cell.row.original.id)}
                >
                  <i className="fa fa-trash " aria-hidden="true"></i>
                </Link>
              </div>
            </div>
          </React.Fragment>
        ),
      },
      {
        id: "user",
        Header: "Admin",
        accessor: "userInfo.firstName",
        Cell: ({ cell }) => (
          <div className="dk-todayAdimgCon">
            <Tooltip
              type="img"
              title={
                cell.row.original.userInfo.firstName +
                " " +
                cell.row.original.userInfo.lastName
              }
              value={
                process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                cell.row.original.userInfo.profileImage
              }
              className="img-todayAdCricle"
            />
          </div>
        ),
      },

      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => (
          <div className="dk-adsStatusBtn">
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                id="dk-adsstatustoggle"
                style={{
                  whiteSpace: "nowrap",
                  background:
                    cell.row.original.status === 1 ? "#31a886" : "#dc3545",
                }}
              >
                {cell.row.original.status === 1 ? "Posted" : "Not Posted"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  data-rowid={cell.row.original.id}
                  id="1"
                  data-text="Posted"
                  onClick={statusUpdate}
                  data-style={{ color: "#fff" }}
                  className={classes.tblDdlActive}
                >
                  Posted
                </Dropdown.Item>
                <Dropdown.Item
                  data-rowid={cell.row.original.id}
                  id="0"
                  data-text="Not Posted"
                  onClick={statusUpdate}
                  data-style={{ color: "#fff" }}
                  className={classes.tblDdlInActive}
                >
                  Not Posted
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      },
      {
        Header: "Update On",
        accessor: "updatedAt",
        Cell: (row, { cell }) => (
          <React.Fragment>
            <CustomDateFormat date={row.row.original.updatedAt} />
          </React.Fragment>
        ),
      },
      {
        Header: "Posted Date",
        accessor: "postedDate",
        Cell: (row, { cell }) => (
          <React.Fragment>
            <DateFormat date={row.row.original.postedDate} />
          </React.Fragment>
        ),
      },
      {
        id: "userId",
        Header: "Recruiter Id",
        accessor: "recruiter",
        show: false,
      },
      {
        Header: "Job Ref",
        accessor: "jobRef",
      },
      {
        Header: "Job Title",
        accessor: "jobTitle",
      },
      {
        Header: "No of Apps",
        accessor: "totalApplication",
      },
      {
        Header: "Job Board",
        accessor: "jobBoard",
      },
      {
        Header: "Company",
        accessor: "company",
      },
      {
        Header: "Language",
        accessor: "language",
      },
      {
        Header: "Role Type",
        accessor: "roleType",
      },
      {
        Header: "Project",
        accessor: "project",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        id: "userInfo",
        Header: "Id",
        accessor: "userInfo.uid",
      },
      {
        id: "statusId",
        Header: "Id",
        accessor: "status",
        show: false,
      },
      {
        Header: "Expire Date",
        accessor: "expiredDate",
        Cell: (row, { cell }) => (
          <React.Fragment>
            <DateFormat date={row.row.original.expiredDate} />
          </React.Fragment>
        ),
      },
      //   {
      //     Header: "Action",
      //     Cell: ({ cell }) => (
      //       <div className="dk-adstractAction">
      //         <Link
      //           to="#"
      //           className="dk-view-btn mr-2"
      //           onClick={() => showViewModal(cell.row.original.id)}
      //         >
      //           <i className="fa fa-eye " aria-hidden="true"></i>
      //         </Link>
      //         <Link
      //           to="#"
      //           className="dk-edit-btn mr-2"
      //           onClick={() => showeditModal(cell.row.original.id)}
      //         >
      //           <i className="fa fa-edit " aria-hidden="true"></i>
      //         </Link>
      //         <Link
      //           to="#"
      //           className="dk-delete-btn"
      //           onClick={() => showDeleteModal(cell.row.original.id)}
      //         >
      //           <i className="fa fa-trash " aria-hidden="true"></i>
      //         </Link>
      //       </div>
      //     ),
      //   },
    ],
    []
  );

  /** Single Checkbox Select */
  const toggleCheckbox = (e, id) => {
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    if (checkedBoxCount > 0) {
      setCountCheck(checkedBoxCount);
      setIsActive(true);
    } else {
      setCountCheck(0);
      setIsActive(false);
    }
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
    if (checkedBoxCount < totalCheckboxes) {
      setCheckBtnTxt("Check All");
    }
    if (checkedBoxCount == totalCheckboxes) {
      setCheckBtnTxt("Uncheck All");
    }
  };
  /** End Single Checkbox Select */
  const [showDelete, setShowDelete] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);
  // const [isView, setIsView] = useState(false);
  //  const [isEdit, setIsEdit] = useState(false);
  const [isDeletePop, setIsDeletePop] = useState(false);
  const [isBulkMovePop, setIsBulkMovePop] = useState(false);

  const showaddNewModal = () => {
    setIsAddNew(true);
  };
  const hideaddNewModal = () => {
    setIsAddNew(false);
  };
  const showeditModal = async (id) => {
    await dispatch(fethById({ id }));
    setRowId(id);
    setIsEdit(true);
  };
  const hideeditModal = () => {
    setIsEdit(false);
  };
  const showViewModal = async (id) => {
    await dispatch(fethById({ id }));
    setRowId(id);
    setIsView(true);
  };
  const hideViewModal = () => {
    setIsView(false);
  };
  const showDeleteModal = async (id) => {
    setIsDeletePop(true);
    setRowId(id);
  };
  const hideDeleteModal = () => {
    setIsDeletePop(false);
    setIsBulkMovePop(false);
  };

  const deleteItem = async () => {
    await dispatch(deleteTracker({ id: rowId }));
  };

  const [bulkLoad, setBulkLoad] = useState(false);

  const deleteSelected = () => {
    setIsBulkMovePop(true);
  };

  const closeDeleteBulk = () => {
    isBulkMovePop(false);
  };

  const moveSelected = async () => {
    setBulkLoad(true);
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    if (checkedBoxCount == 0) {
    } else {
      var canIds = [];
      $.each($(".chkCandidate:checked"), function () {
        canIds.push($(this).val());
      });

      await dispatch(todaysDataMove({ ids: canIds }));
    }
  };
  const statusUpdate = (e) => {
    dispatch(updateStatus({ id: e.target.dataset.rowid, status: e.target.id }));
  };
  const showEditModal = () => {
    setIsEdit(true);
  };
  const hideEditModal = () => {
    setIsEdit(false);
  };
  // const showViewModal = () => {
  //     setIsView(true)
  // }
  // const hideViewModal = () => {
  //     setIsView(false)
  // }
  // const showreorderModal = () => {
  //     setIsReorder(true)
  // }

  const [isActive, setIsActive] = useState(false);
  const [countCheck, setCountCheck] = useState(0);
  const [checkBtnTxt, setCheckBtnTxt] = useState("Check All");

  const handleToggle = () => {
    $(".chkCandidate").prop("checked", false);
    setIsActive(false);
  };

  const toggleCheckAll = () => {
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
    if (checkedBoxCount < totalCheckboxes) {
      setCheckBtnTxt("Uncheck All");
      setCountCheck(totalCheckboxes);
      $(".chkCandidate").prop("checked", true);
      // setCheckedBoxes(checkedBoxes.filter((v, i, a) => a.indexOf(v) === i))
    }
    if (checkedBoxCount == totalCheckboxes) {
      setCheckBtnTxt("Check All");
      setCountCheck(0);
      $(".chkCandidate").prop("checked", false);
      setIsActive(false);
    }
  };
  const closeDelete = () => {
    setIsDeletePop(false);
  };

  return (
    <>
      {/* {
                (isDelete) ? <DeleteModal onHide={hideDeleteModal} /> : null
            } */}
      {isEdit ? <EditModal onHide={hideEditModal} /> : null}
      {isView ? <ViewModal onHide={hideViewModal} /> : null}

      {/* <ReorderModal dataList={data} /> */}
      {isDeletePop ? (
        <DeleteModal
          id={rowId}
          onHide={hideDeleteModal}
          deleteItem={deleteItem}
          handleClose={closeDelete}
        />
      ) : null}

      {isBulkMovePop ? (
        <BulkMoveData
          id={rowId}
          onHide={hideDeleteModal}
          deleteItem={moveSelected}
          handleClose={closeDeleteBulk}
        />
      ) : null}

      <div className="container-fluid dkg-todays-adstrackermainCon-14">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="dk-todayAdsTableHeaderCon24">
              <Table
                data={data}
                columns={columns}
                loading={isDataLoading}
                isInsert={isInsert}
                isUpdate={isUpdate}
                isDataDelete={isDelete}
                className="table dk-todayAdsTable"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="dk-selectNo">{countCheck}</div>
          <div className="dk-rowSelected">
            <span>Row Selected</span>
          </div>
          <div className="dk-checkAll" onClick={toggleCheckAll}>
            <span>
              <i className="fas fa-check"></i>
            </span>
            {checkBtnTxt}
          </div>
          {bulkLoad ? (
            <div className={`dk-checkAll ` + classes.backdrop}>
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <div className="dk-checkAll" onClick={deleteSelected}>
              <span>
                <i className="fa fa-share"></i>
              </span>
              Move to Live
            </div>
          )}
          <div
            className="dk-checkAll d-flex align-items-center justify-content-center"
            onClick={handleToggle}
          >
            <span>
              <i className="fas fa-times"></i>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TodayAds;
