import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import "./camedit.css";
import Submit from "../../../../ui/submitButton";
import UserList from "../../../../ui/userList/";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../utils/messages/messageSlice";
import {
  updateTracker,
  clearState,
} from "../../../../../slice/ads/todaysAds/todaysAdsSlice";
import SelectInstance from "../../../../ui/SelectPicker/SelectInstance";

import JobBoardDropdown from "../../dropdown/JobBoardDropdown";
import CompanyDropdown from "../../dropdown/CompanyDropdown";
import RoleTypeDropdown from "../../dropdown/RoleTypeDropdown";
import LanguageDropdown from "../../dropdown/LanguageDropdown";

const Edit = ({ onHide, id }) => {
  const { adsJobboardList, adsCompanyList, adsLanguageList, adsRoleTypeList } =
    useSelector((state) => state.common);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setShow(true);
  }, [id]);

  const {
    detailData,
    dataList,
    isDataLoading,
    isInsert,
    isUpdate,
    isError,
    isDelete,
    errorMessage,
    jobBoardList,
    companyList,
    rolesList,
    languageList,
  } = useSelector((state) => state.todaysAdsTracker);

  const [rowId, setRowId] = useState(detailData.id);
  const [jobTitle, setJobTitle] = useState(detailData.jobTitle);
  const [jobRef, setJobRef] = useState(detailData.jobRef);
  const [companyName, setCompanyName] = useState(detailData.company);
  const [jobBoard, setJobBoard] = useState(detailData.jobBoard);
  const [roleType, setRoleType] = useState(detailData.roleType);
  const [language, setLanguage] = useState(detailData.language);
  const [project, setProject] = useState(detailData.project);
  const [city, setCity] = useState(detailData.city);
  const [totalApplications, setTotalApplications] = useState(
    detailData.totalApplication
  );
  const [country, setCountry] = useState(detailData.country);
  const [postedDate, setPostedDate] = useState(detailData.postedDate);
  const [expireDate, setExpireDate] = useState(detailData.expiredDate);
  const [userId, setUserId] = useState(detailData.userInfo.id);
  const [userImg, setUserImg] = useState(
    process.env.REACT_APP_IMG_CLOUD_BASE_URL + detailData.userInfo.profileImage
  );
  const [userImgName, setUserImgName] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const selectUser = (e) => {
    setUserImgName(e.target.alt);
    setUserImg(e.target.src);
    setUserId(e.target.dataset.id);
  };

  const handleChangeSelect = (event, data) => {
    const { name, value } = data;
    switch (name) {
      case "company":
        setCompanyName(value);
        break;

      case "jobBoard":
        setJobBoard(value);
        break;

      case "role":
        setRoleType(value);
        break;
      case "language":
        setLanguage(value);
        break;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "jobRef":
        setJobRef(value);
        break;

      case "jobTitle":
        setJobTitle(value);
        break;
      case "totalApplications":
        setTotalApplications(value);
        break;

      case "project":
        setProject(value);
        break;

      case "city":
        setCity(value);
        break;

      case "country":
        setCountry(value);
        break;

      case "postedDate":
        setPostedDate(value);
        break;
      case "expireDate":
        setExpireDate(value);
        break;
      default:
        break;
    }
  };
  const handleButtonClick = async () => {
    if (userId == "0") {
      dispatch(showError({ msg: "Please fill all fields." }));
    } else {
      if (!loading) {
        setSuccess(false);
        setLoading(true);
        await dispatch(
          updateTracker({
            id: rowId,
            recruiter: userId,
            jobRef: jobRef,
            jobTitle: jobTitle,
            company: companyName,
            jobBoard: jobBoard,
            roleType: roleType,
            language: language,
            project: project,
            city: city,
            totalApplication: totalApplications,
            country: country,
            postedDate: postedDate,
            expiredDate: expireDate,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (isUpdate) {
      dispatch(showSuccess({ msg: "Tracker update successfully." }));
      dispatch(clearState());
      setJobRef("");
      setJobTitle("");
      setCompanyName("");
      setJobBoard("");
      setTotalApplications("0");
      setRoleType("");
      setLanguage("");
      setCity("");
      setCountry("");
      setPostedDate("");
      setExpireDate("");
      setUserId("0");
      onHide();
      setSuccess(true);
      setLoading(false);
    }
    if (isError) {
      dispatch(showError({ msg: errorMessage }));
      dispatch(clearState());
      setSuccess(false);
      setLoading(false);
    }
  }, [isUpdate, isError]);

  return (
    <React.Fragment>
      <Modal
        show={true}
        onHide={onHide}
        dialogClassName="dk-compaginsEditPop"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-compaginsEdit">
          <form>
            <div className="row">
              <div className="col-md-3 mr-auto">
                <div className="form-group">
                  <label htmlFor="">Recruiter</label>
                  <label htmlFor="" className="mb-0">
                    <input type="hidden" id="userId" value={userId} />
                    <Dropdown className="dk-globalUserDropdown">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {userId !== "0" ? (
                          <img src={userImg} className="select-user-img" />
                        ) : (
                          <i className="fas fa-user"></i>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="d-flex">
                          <UserList onClick={selectUser} />
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>No of Ads</label>
                  <input
                    type="text"
                    className="form-control broderContrl"
                    name="totalApplications"
                    value={totalApplications}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-9">
                <div className="form-group">
                  <label>Job Title</label>
                  <input
                    type="text"
                    className="form-control broderContrl"
                    name="jobTitle"
                    value={jobTitle}
                    onChange={handleChange}
                    placeholder={detailData.jobBoard.name}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Job Board</label>
                  <div className="dk-camEditFilter">
                    <JobBoardDropdown
                      list={adsJobboardList}
                      type="jobBoard"
                      placeholder={jobBoard}
                      onChange={handleChangeSelect}
                      name="jobBoard"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Job Ref</label>
                  <input
                    type="text"
                    className="form-control broderContrl"
                    name="jobRef"
                    value={jobRef}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Company</label>
                  <div className="dk-camEditFilter">
                    <CompanyDropdown
                      list={adsCompanyList}
                      type="company"
                      placeholder={companyName}
                      onChange={handleChangeSelect}
                      name="company"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Project</label>
                  <div className="dk-camEditFilter">
                    <input
                      type="text"
                      className="form-control broderContrl"
                      name="project"
                      value={project}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Language</label>
                  <div className="dk-camEditFilter">
                    <LanguageDropdown
                      list={adsLanguageList}
                      type="language"
                      placeholder={language}
                      onChange={handleChangeSelect}
                      name="language"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Role Type</label>
                  <div className="dk-camEditFilter">
                    <RoleTypeDropdown
                      list={adsRoleTypeList}
                      type="role"
                      placeholder={roleType}
                      onChange={handleChangeSelect}
                      name="role"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Country</label>
                  <input
                    type="text"
                    className="form-control broderContrl"
                    name="country"
                    value={country}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>City</label>
                  <div className="dk-camEditFilter">
                    <input
                      type="text"
                      className="form-control broderContrl"
                      name="city"
                      value={city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Posted Date</label>
                  <input
                    type="date"
                    className="form-control broderContrl"
                    name="postedDate"
                    value={postedDate}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Expire Date</label>
                  <div className="dk-adstrackPopDrop w-100">
                    <input
                      type="date"
                      className="form-control broderContrl"
                      name="expireDate"
                      value={expireDate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="dk-adstrackbtnCont">
                  <Submit
                    txt="UPDATE"
                    loading={loading}
                    success={success}
                    onClick={handleButtonClick}
                    className="dk-adstrackaddbtn"
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Edit;
